export const TestQueriesByNodeId = `
    query tests($nodeId: String!, $skip: Int, $take: Int) {
        tests(where: { nodeId: $nodeId }, orderBy: { startTime: desc }, skip: $skip, take: $take) {
            rows {
              id
              node {
                  id
              }
              address
              startTime
              endTime
              type
              initialBatVolt
              finalBatVolt
              initialBatTemp
              finalBatTemp
              led1Current
              led2Current
              led1Volt
              led2Volt
              batSlope
              slopeValid
              status
            }
            count
        }
    }
`;

export const NodesQueriesBySiteID = `
    query nodes($siteId: ID!, $skip: Int, $take: Int) {
        nodes(where: {gateway: { siteId: $siteId }}, skip: $skip, take: $take) {
          rows {
            id
            address
            name
            status
            lastUpdated
            lastSeen
            location
            rssi
            wpStackVersion
            appVersion
            bleAppCommsVersion
            sbcbNo
            assetNo
            installDate
            scheduledTest
            scheduleFreq
            scheduleHour
            scheduleMin
            serialNumber
            lastTest {
              id
              type
              status
              startTime
            }
            lastCompletedTest {
              id
              status
              startTime
              endTime
            }
            product {
                id
                name
                isEmgLight
                maintained
                hasSensor
            }
            deviceGroup {
                id
                name
            }
          }
          count
          originalCount
        }
    }
`;

export const NodesQueriesBySiteIDWithFilter = `
    query nodesWithFilter($siteId: ID!, $skip: Int, $take: Int, $orderBy: [NodeOrderByInput!], $deviceFilters: [Int!], $statusFilters: [Int!], $testTypeFilters: [Int!], $locationFilters: [String!], $wirepasAddress: String, $assetNo: String, $deviceName: String, $serialNumber: String) {
        nodesWithFilter(siteId: $siteId, skip: $skip, take: $take, orderBy: $orderBy deviceFilters: $deviceFilters, statusFilters: $statusFilters, testTypeFilters: $testTypeFilters, locationFilters: $locationFilters, wirepasAddress: $wirepasAddress, assetNo: $assetNo, deviceName: $deviceName, serialNumber: $serialNumber) {
          rows {
            id
            address
            name
            status
            lastUpdated
            lastSeen
            location
            rssi
            wpStackVersion
            appVersion
            bleAppCommsVersion
            sbcbNo
            assetNo
            installDate
            scheduledTest
            scheduleFreq
            scheduleHour
            scheduleMin
            serialNumber
            lastTest {
              id
              type
              status
              startTime
            }
            lastCompletedTest {
              id
              status
              startTime
              endTime
            }
            product {
                id
                name
                isEmgLight
                maintained
                hasSensor
            }
            deviceGroup {
                id
                name
            }
          }
          count
          originalCount
          productList {
            name
            id
          }
          locationList
        }
    }
`;

export const NodeQueriesByID = `
    query node($id: ID!) {
        node(id: $id) {
            id
            address
            name
            status
            lastUpdated
            lastSeen
            location
            rssi
            wpStackVersion
            appVersion
            bleAppCommsVersion
            sbcbNo
            assetNo
            installDate
            scheduledTest
            scheduleFreq
            scheduleHour
            scheduleMin
            serialNumber
            lastTest {
              id
              type
              status
              startTime
            }
            lastCompletedTest {
              id
              status
              startTime
              endTime
            }
            product {
                id
                name
                isEmgLight
                maintained
                hasSensor
            }
            deviceGroup {
                id
                name
            }
        }
    }
`;

export const NodesQueriesByDevicegroupID = `
    query nodes($deviceGroupId: ID!, $skip: Int, $take: Int) {
        nodes(where: { deviceGroupId: $deviceGroupId }, skip: $skip, take: $take) {
          rows {
            id
            address
            name
            status
            location
            assetNo
            product {
                id
                name
            }
          }
          count
          originalCount
        }
    }
`;

export const NodesQueriesBySiteIdWithoutDevicegroup = `
    query nodesWithFilter($siteId: ID!, $skip: Int, $take: Int, $deviceFilters: [Int!], $locationFilters: [String!]) {
        nodesWithFilter(where: { deviceGroupId: null, product: { hasSensor: true } }, siteId: $siteId, skip: $skip, take: $take, deviceFilters: $deviceFilters, locationFilters: $locationFilters) {
          rows {
            id
            address
            name
            location
            product {
                name
            }
          }
          count
          locationList
        }
    }
`;

export const ScheduleQueries = `
    query schedules($nodeId: String!) {
        schedules(where: { nodeId: $nodeId }, take: 100) {
            rows {
              id
              nodeId
              name
              since
              repeat
              interval
              type
            }
            count
        }
    }
`;

export const motionEventsQuery = `
  query MotionEvents($nodeId: String!, $skip: Int, $take: Int) {
    motionEvents(where: { nodeId: $nodeId },  orderBy: {start: desc}, skip: $skip, take: $take) {
      rows {
        id
        start
        end
        fromEmergency
      }
      count
    }  
  }
`

export const batteryReplacementEventsQuery = `
  query BatteryReplacementEvents($nodeId: String!, $skip: Int, $take: Int) {
    batteryReplacementEvents(where: { nodeId: $nodeId },  orderBy: {time: desc}, skip: $skip, take: $take) {
      rows {
        id
        time
        chargeCycleCount
      }
      count
    }  
  }
`

export const powerEventsQuery = `
  query PowerEvents($nodeId: String!, $skip: Int, $take: Int) {
    powerEvents(where: { nodeId: $nodeId },  orderBy: {time: desc}, skip: $skip, take: $take) {
      rows {
        id
        time
        status
      }
      count
    }  
  }
`

export const deviceLogsQuery = `
query deviceLogsQuery($nodeId: String!, $skip: Int, $take: Int) {
  nodeLogs(where: { nodeId: $nodeId }, orderBy: {time: desc}, skip: $skip, take: $take) {
    rows {
      id
      time
      mainsRunTime
      minimumTemperature
      maximumTemperature
      chargeCycles
      emgLedRuntime
      scheduledTest
    }
    count
  }
}
`;

export const siteStatsQuery = `
query siteStats($id: ID!) {
  siteStats (id: $id) {
    recentFailedTests {
        node {
            id
            name
            address
        }
        endTime
        status
    }
  }
}
`;

export const siteUpcomingTests = `
query upcomingtests($id: ID!) {
  upcomingTests(id: $id) {
    tests {
      node {
        id
        name
        address
        location
        product {
          id
          name
          isEmgLight
          maintained
          hasSensor
        }
      }
      type
      date
    }
  }
}
`;

export const sitesInfoQuery =
  `query sitesInfo($orderBy: [SiteOrderByInput!], $skip: Int, $take: Int, $tenantFilters: [ID!], $where: SiteWhereInput) {
    sitesInfo(orderBy: $orderBy, skip: $skip, take: $take, tenantFilters: $tenantFilters, where: $where) {
      rows {
        id
        name
        owner
        agent
        address
        latitude
        longitude
        code
        timezone
        installDate
        failedTests
        unresponsiveTests
        gatewayOnline
        gatewayOffline
        diagnosticsEnabled
        offlineNodes
      }
      count
    }
  }
`;

export const sensorDataQuery = `
query sensordata($nodeId: ID!) {
  nodeLogs: sensorData(nodeId: $nodeId) {
    time
    temperature
    relativeHumidity
    energyLevel
    sensorIllumination
    airPressure
    tvoc
    co2
    noise
  }
}
`;

export const sensorLogsQuery = `
query SensorLogs($nodeId: String!, $skip: Int, $take: Int) {
  nodeLogs: sensorLogs(where: { nodeId: $nodeId }, orderBy: {time: desc}, skip: $skip, take: $take) {
    rows {
      time
      temperature
      relativeHumidity
      energyLevel
      sensorIllumination
      airPressure
      tvoc
      co2
      noise
    }
    count
  }
}
`;

export const siteDetailsQuery = `
query site($id: ID!) {
  site(id : $id) {
    id
    name
    owner
    agent
    address
    latitude
    longitude
    code
    timezone
    diagnosticsEnabled
  }
}
`;

export const failedNodesQuery = `
  query failedNodes($siteId: ID!, $skip: Int, $take: Int) {
    failedNodes(siteId: $siteId, skip: $skip, take: $take) {
      rows {
        id
        address
        name
        status
        lastUpdated
        lastSeen
        location
        rssi
        wpStackVersion
        appVersion
        bleAppCommsVersion
        sbcbNo
        assetNo
        installDate
        scheduledTest
        scheduleFreq
        scheduleHour
        scheduleMin
        lastTest {
          id
          type
          status
          startTime
        }
        lastCompletedTest {
          id
          status
          startTime
          endTime
        }
        product {
            id
            name
            isEmgLight
            maintained
            hasSensor
        }
        deviceGroup {
            id
            name
        }
      }
      count
      originalCount
    }
  }
`;

export const user = `
query user {
  me {
    id,
    name,
    email,
    role,
    mfaEnabled
  }
}
`;
export const users = `
query users($orderBy: [UserOrderByInput!], $skip: Int, $take: Int, $where: UserWhereInput) {
  users (orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    count,
    rows {
      id,
      name,
      email,
      role,
      mfaEnabled
    }
  }
}
`;

export const tenant = `
query tenant($id: ID!) {
  tenant (id: $id) {
    id,
    name,
    address,
    phone
  }
}
`;
export const tenants = `
query tenants($orderBy: [TenantOrderByInput!], $skip: Int, $take: Int, $where: TenantWhereInput) {
  tenants (orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    count,
    rows {
      id,
      name,
      address,
      phone
    }
  }
}
`;
export const tenantsName = `
query tenants($orderBy: [TenantOrderByInput!], $skip: Int, $take: Int, $where: TenantWhereInput) {
  tenants (orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    count,
    rows {
      id,
      name
    }
  }
}
`;

export const devicegroup = `
query devicegroup($id: ID!) {
  devicegroup (id: $id) {
    id,
    name,
    description,
    dateCreated,
    siteId
  }
}
`;
export const devicegroups = `
query devicegroups($orderBy: [DeviceGroupOrderByInput!], $skip: Int, $take: Int, $where: DeviceGroupWhereInput) {
  devicegroups (orderBy: $orderBy, skip: $skip, take: $take, where: $where, _count: { nodes: true }) {
    count,
    rows {
      id,
      name,
      description,
      dateCreated,
      siteId,
      _count { nodes },
    }
  }
}
`;
export const devicegroupsName = `
query devicegroups($siteId: String!) {
  devicegroups (where: { siteId: $siteId }) {
    count,
    rows {
      id,
      name
    }
  }
}
`;

export const sitesName = `
query sites($orderBy: [SiteOrderByInput!], $skip: Int, $take: Int, $where: SiteWhereInput) {
  sites (orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    count,
    rows {
      id,
      name
    }
  }
}
`;

export const entity = `
query entity($id: ID!) {
  entity (id: $id) {
    id,
    name,
    pocName,
    pocNumber,
    pocEmail
  }
}
`;
export const entities = `
query entities($orderBy: [EntityOrderByInput!], $skip: Int, $take: Int, $where: EntityWhereInput) {
  entities (orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    count,
    rows {
      id,
      name,
      pocName,
      pocNumber,
      pocEmail
    }
  }
}
`;
export const entitySitesQuery = `
  query entitySites($entityId: ID) {
    sites(where: { entityId: $entityId }) {
      count
      rows {
        id
        name
        address
      }
    }
  }
`
export const entityUsersQuery = `
  query entityUsers($entityId: ID) {
    usersentities(where: { entityId: $entityId }) {
      count
      rows {
        user {
          id
          name
          email
        }
      }
    }
  }
`

export const entityAllUsersQuery = `
  query entityAllUsers {
    users {
      count
      rows {
        id
        name
        email
      }
    }
  }
`

export const siteGatewaysQuery = `
  query siteGateways($siteId: ID) {
    gateways(where: { siteId: $siteId }) {
      count
      rows {
        id
        name
        address
      }
    }
  }
`

export const siteTenantsQuery = `
  query siteTenants($siteId: ID) {
    tenants(where: { siteId: $siteId }) {
      count
      rows {
        id
        name
        address
      }
    }
  }
`

export const siteIdQueryByNodeId = `
  query siteId($nodeId: ID) {
    sites(where: { gateways: { some: { nodes: { some: { id: $nodeId } } } } } ) {
      count
      rows {
        id
      }
    }
  }
`

export const sensorSettingsQuery = `
  query sensorSettings($nodeId: String!) {
    sensorSettings(where: { nodeId: $nodeId }) {
      rows {
        id
        maxDimLevel
        maxFadeS
        maxTimeoutS
        medDimLevel
        medFadeS
        medTimeoutS
        minDimLevel
        sensitivity
      }
      count
    }  
  }
`

export const groupSensorSettingQuery = `
  query groupSensorSetting($id: ID!) {
    groupSensorSetting(id: $id) {
      id
      maxDimLevel
      maxFadeS
      maxTimeoutS
      medDimLevel
      medFadeS
      medTimeoutS
      minDimLevel
    }  
  }
`

export const requestFirmwareSensorSettingsQuery = `
  query requestFirmwareSensorSetting($nodeId: ID!) {
    requestFirmwareSensorSetting(id: $nodeId) 
  }
`

/*
export const airSensorQuery = `
query airSensorLogs($nodeId: ID!, $count: Int!) {
    nodeLogs: sensorLogs(where: {node: {id: {equals: $nodeId}}}, orderBy: {time: desc}, take: $count) {
        time
        temperature
        relativeHumidity
        energyLevel
        sensorIllumination
        airPressure
        tvoc
    }
}
`;
*/

export const sensorProfilesQuery = `
  query sensorProfiles($skip: Int, $take: Int, $where: SensorProfileWhereInput) {
    sensorProfiles(skip: $skip, take: $take, where: $where) {
      count
      rows {
        id
        name
        siteId
        maxDimLevel
        maxFadeS
        maxTimeoutS
        medDimLevel
        medFadeS
        medTimeoutS
        minDimLevel
        sensitivity
        site {
          id
          name
        }
      }
    }
  }
`;

export const sensorProfileQuery = `
  query sensorProfile($id: ID!) {
    sensorProfile(id: $id) {
      id
      name
      siteId
      maxDimLevel
      maxFadeS
      maxTimeoutS
      medDimLevel
      medFadeS
      medTimeoutS
      minDimLevel
      sensitivity
    }
  }
`;

export const sensorProfilesBySiteQuery = `
  query sensorProfilesBySite($siteId: ID!) {
    sensorProfilesBySite(siteId: $siteId) {
      count
      rows {
        id
        name
        siteId
        maxDimLevel
        maxFadeS
        maxTimeoutS
        medDimLevel
        medFadeS
        medTimeoutS
        minDimLevel
        sensitivity
      }
    }
  }
`;
