<template>
    <nav id="nav" class="p-3">
        <!-- <div class="container"> -->
            <div class="d-flex justify-content-between align-items-center">
                <router-link :to="'/sites'"><img src="@/assets/img_logo.png" width="100" /></router-link>
                <div class="user-wrapper d-flex align-items-center" v-if="loggedUser">
                    <b-dropdown toggle-class="btn-logout btn-normal" :text="loggedUser" right>
                        <b-dropdown-item href="/usersmanage" v-if="userIsAdmin">User management</b-dropdown-item>
                        <b-dropdown-item href="/sitesmanage" v-if="userIsAdmin">Site management</b-dropdown-item>
                        <b-dropdown-item href="/entitiesmanage" v-if="userIsAdmin">Entity management</b-dropdown-item>
                        <b-dropdown-item href="/tenantsmanage" v-if="userIsAdmin">Tenant management</b-dropdown-item>
                        <b-dropdown-item href="/devicegroupsmanage" v-if="userIsAdmin">Group management</b-dropdown-item>
                        <b-dropdown-item href="/sensorprofilesmanage" v-if="userIsAdmin">Sensor Profiles</b-dropdown-item>
                        <b-dropdown-item @click="loginUserDetail()">Change Password</b-dropdown-item>
                        <b-dropdown-divider v-if="userIsAdmin"></b-dropdown-divider>
                        <b-dropdown-item @click="logout()">Logout</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        <!-- </div> -->
    </nav>
</template>

<script>
import {mapGetters} from 'vuex';
import { USER_ROLE_MAP } from '../constants.js'

export default {
    name: 'HeaderComponent',
    data() {
        return {
            loggedUser: ''
        }
    },
    mounted() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            this.loggedUser = user.user.email;
        }
    },
    computed: {
        ...mapGetters({
            user: 'main/user'
        }),
        userIsAdmin() {
            return USER_ROLE_MAP[this.user?.user?.role] === "admin";
        }
    },
    methods: {
        logout() {
            localStorage.removeItem('user');
            this.$router.push("/");
        },
        loginUserDetail() {
            this.$router.push(`/usersetting/${this.user?.user?.id}`);
        }
    }
}
</script>

<style>

</style>
