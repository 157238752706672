import Vue from 'vue';
import VueRouter from 'vue-router';
// import auth from '../auth/auth';
// import app from "@/main.js"
Vue.use(VueRouter)
// eslint-disable-next-line


const routes = [
    {
        path: '/',
        name: 'AuthenticationComponent',
        component: () => import('../components/AuthenticationComponent.vue'),
    }, {
        path: '/sites',
        name: 'SiteListComponent',
        component: () => import('../pages/SitesPage.vue')
    }, {
        path: '/site/:id',
        name: 'SiteDetail',
        component: () => import('../pages/SiteDetail.vue')
    }, {
        path: '/device/:siteId/:deviceId',
        name: 'DeviceDetail',
        component: () => import('../pages/DeviceDetail.vue')
    } , {
        path: '/sitesmanage',
        name: 'SitesManageListComponent',
        component: () => import('../pages/SitesManagePage.vue')
    }, {
        path: '/sitesmanage/:id',
        name: 'SiteManageDetail',
        component: () => import('../pages/SiteManageDetail.vue')
    } , {
        path: '/usersmanage',
        name: 'UserManageListComponent',
        component: () => import('../pages/UsersManagePage.vue')
    }, {
        path: '/usermanage/:id',
        name: 'UserManageDetail',
        component: () => import('../pages/UserManageDetail.vue')
    }, {
        path: '/usersetting/:id',
        name: 'UserSettingDetail',
        component: () => import('../pages/UserSettingDetail.vue')
    }, {
        path: '/entitiesmanage',
        name: 'EntitiesManageListComponent',
        component: () => import('../pages/EntitiesManagePage.vue')
    }, {
        path: '/entitiesmanage/:id',
        name: 'EntityManageDetail',
        component: () => import('../pages/EntityManageDetail.vue')
    }, {
        path: '/tenantsmanage',
        name: 'TenantsManageListComponent',
        component: () => import('../pages/TenantsManagePage.vue')
    }, {
        path: '/tenantsmanage/:id',
        name: 'TenantManageDetail',
        component: () => import('../pages/TenantManageDetail.vue')
    }, {
        path: '/devicegroupsmanage',
        name: 'DevicegroupsManageListComponent',
        component: () => import('../pages/DevicegroupsManagePage.vue')
    }, {
        path: '/devicegroupsmanage/:id',
        name: 'DevicegroupManageDetail',
        component: () => import('../pages/DevicegroupManageDetail.vue')
    }, {
        path: '/sensorprofilesmanage',
        name: 'SensorProfilesManageListComponent',
        component: () => import('../pages/SensorProfilesManagePage.vue')
    }, {
        path: '/sensorprofilesmanage/:id',
        name: 'SensorProfileManageDetail',
        component: () => import('../pages/SensorProfileManageDetail.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})
  
export default router;