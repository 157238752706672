export const getters = {
    // eslint-disable-next-line
    sites: (state) => {
        if (state.sites) {
            return state.sites?.rows || state.sites;
        }

        if (localStorage.getItem('sites')) {
            const res = JSON.parse(localStorage.getItem('sites'));

            return res?.rows || res
        }

        return [];
    },
    site: (state) => {
        return state.site;
    },
    sitesCount: (state) => {
        if (state.sites) {
            return state.sites?.count || 0;
        }

        if (localStorage.getItem('sites')) {
            const res = JSON.parse(localStorage.getItem('sites'));

            return res?.count || 0;
        }

        return 0;
    },
    nodeTests: (state) => {
        return state.nodeTests?.rows || []
    },
    nodeTestsCount: (state) => {
        return state.nodeTests?.count || 0
    },
    siteNodes: (state) => {
        return state.siteNodes?.rows || []
    },
    siteProductList: (state) => {
      return state.siteProductList || []
    },
    siteLocationList: (state) => {
      return state.siteLocationList || []
    },
    siteNodesCount: (state) => {
        return state.siteNodes?.count || 0
    },
    siteNodesOriginalCount: (state) => {
        return state.siteNodes?.originalCount || 0
    },
    hasTestRequest: (state) => (nodeId) => {
        return state.nodeData[nodeId].hasTestRequest;
    },
    getNodesData: (state) => {
        return state.nodeData;
    },
    getNodeData: (state) => (nodeId) => {
        return state.nodeData[nodeId];
    },
    hasCancelRequest: (state) => (nodeId) => {
        return state.nodeData[nodeId].hasCancelRequest;
    },
    schedulesByDevice: (state) => {
        return state.schedulesByDevice
    },
    serverBLEVersion: (state) => {
        return state.serverBLEVersion;
    },
    timezone: (state) => {
        return state.siteTimezone;
    },
    upcomingTests: (state) => {
        return state.siteUpcomingTests;
    },
    deviceLogsComponent: (state) => {
        return state.deviceLogsComponent;
    },
    /* eslint-disable-next-line */
    user: (state) => {
        return JSON.parse(localStorage.getItem('user'));
    },
    users: (state) => {
        if (state.users) {
            return state.users?.rows || state.users;
        }
            
        if (localStorage.getItem('users')) {
            const res = JSON.parse(localStorage.getItem('users'));

            return res?.rows || res
        }
        return [];
    },
    usersCount: (state) => {
        if (state.users) {
            return state.users?.count || 0;
        }

        if (localStorage.getItem('users')) {
            const res = JSON.parse(localStorage.getItem('users'));

            return res?.count || 0;
        }
        return 0;
    },
    
    tenant: () => {
        return JSON.parse(localStorage.getItem('tenant'));
    },
    tenants: (state) => {
        if (state.tenants) {
            return state.tenants?.rows || state.tenants;
        }

        if (localStorage.getItem('tenants')) {
            const res = JSON.parse(localStorage.getItem('tenants'));

            return res?.rows || res
        }
        return [];
    },
    tenantsCount: (state) => {
        if (state.tenants) {
            return state.tenants?.count || 0;
        }

        if (localStorage.getItem('tenants')) {
            const res = JSON.parse(localStorage.getItem('tenants'));

            return res?.count || 0;
        }
        return 0;
    },
    tenantsName: (state) => {
        if (state.tenantsName) {
            return state.tenantsName?.rows || state.tenantsName;
        }

        if (localStorage.getItem('tenantsName')) {
            const res = JSON.parse(localStorage.getItem('tenantsName'));

            return res?.rows || res
        }
        return [];
    },
    
    devicegroup: () => {
        return JSON.parse(localStorage.getItem('devicegroup'));
    },
    devicegroups: (state) => {
        if (state.devicegroups) {
            return state.devicegroups?.rows || state.devicegroups;
        }

        if (localStorage.getItem('devicegroups')) {
            const res = JSON.parse(localStorage.getItem('devicegroups'));

            return res?.rows || res
        }
        return [];
    },
    devicegroupsCount: (state) => {
        if (state.devicegroups) {
            return state.devicegroups?.count || 0;
        }

        if (localStorage.getItem('devicegroups')) {
            const res = JSON.parse(localStorage.getItem('devicegroups'));

            return res?.count || 0;
        }
        return 0;
    },
    devicegroupsName: (state) => {
        if (state.devicegroupsName) {
            return state.devicegroupsName?.rows || state.devicegroupsName;
        }

        if (localStorage.getItem('devicegroupsName')) {
            const res = JSON.parse(localStorage.getItem('devicegroupsName'));

            return res?.rows || res
        }
        return [];
    },
    
    sitesName: (state) => {
        if (state.sitesName) {
            return state.sitesName?.rows || state.sitesName;
        }

        if (localStorage.getItem('sitesName')) {
            const res = JSON.parse(localStorage.getItem('sitesName'));

            return res?.rows || res
        }
        return [];
    },
    
    entity: () => {
        return JSON.parse(localStorage.getItem('entity'));
    },
    entities: (state) => {
        if (state.entities) {
            return state.entities?.rows || state.entities;
        }

        if (localStorage.getItem('entities')) {
            const res = JSON.parse(localStorage.getItem('entities'));

            return res?.rows || res
        }
        return [];
    },
    entitiesCount: (state) => {
        if (state.entities) {
            return state.entities?.count || 0;
        }

        if (localStorage.getItem('entities')) {
            const res = JSON.parse(localStorage.getItem('entities'));

            return res?.count || 0;
        }
        return 0;
    },
    motionEvents: (state) => {
        return state.motionEvents?.rows || []
    },
    motionEventsCount: (state) => {
        return state.motionEvents?.count || 0
    },
    batteryReplacementEvents: (state) => {
        return state.batteryReplacementEvents?.rows || []
    },
    batteryReplacementEventsCount: (state) => {
        return state.batteryReplacementEvents?.count || 0
    },
    powerEvents: (state) => {
        return state.powerEvents?.rows || []
    },
    powerEventsCount: (state) => {
        return state.powerEvents?.count || 0
    },
    faultyNodes: (state) => {
        return state.siteFaultSummary?.rows || []
    },
    faultyNodesCount: (state) => {
        return state.siteFaultSummary?.count || 0
    },
    siteNodesList_UserControl: (state) => {
        return state.siteNodesList_UserControl;
    },
    perPage: (state) => {
        if (state.perPage) {
            return state.perPage;
        }

        const savedPerPage = localStorage.getItem('perPage');
        if (savedPerPage) {
            return parseInt(savedPerPage);
        }

        return 10; // default value
    },
    siteUserControl: () => (siteId) => {
        try {
            const stored = sessionStorage.getItem('siteUserControls');
            if (stored) {
                const controls = JSON.parse(stored);
                return controls[siteId] || null; // Return null if no user control exists for this site
            }
        } catch (e) {
            console.error('Error reading from sessionStorage:', e);
        }
        return null; // Return null if any error or no data
    },
    sensorProfile: state => state.sensorProfile,
    sensorProfiles: state => state.sensorProfiles,
    sensorProfilesCount: state => state.sensorProfilesCount,
    sensorProfilesForSite: state => state.sensorProfilesForSite,
    sensorProfilesBySiteId: state => (siteId) => {
        return state.sensorProfilesForSite[siteId] || [];
    }
}